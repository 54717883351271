@tailwind base;
@tailwind components;
@tailwind utilities;

p,
h1,
h2,
h3,
label,
span {
  @apply text-primary-900;
}

/* Override for elements inside div with class 'white-text' */
div.white-text p,
div.white-text h1,
div.white-text h2,
div.white-text h3,
div.white-text label,
div.white-text span {
  @apply text-secondary-50;
}

input[type='text']:not([id*='react-select']):not([id*='location-input']),
input[type='email'],
input[type='search'],
input[type='number'],
input[type='url'],
textarea,
input#google-autocomplete {
  @apply h-[50px] w-full rounded-[15px] border-2 border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50 font-medium text-primary-900 transition-colors focus:border-primary-300 focus:border-opacity-100 focus:ring-0;
}
input#google-autocomplete,
.MuiInputBase-root {
  @apply h-[50px] w-full rounded-[15px] border-2 border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50 font-medium text-primary-900 transition-colors focus:border-primary-300 focus:border-opacity-100 focus:ring-0;
}

.light .MuiInputBase-root {
  @apply bg-secondary-50;
}

.MuiInputBase-root {
  @apply !rounded-[15px] focus-within:border-primary-300 focus-within:border-opacity-100;
}

textarea {
  @apply w-full rounded-[15px] border-2 border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50 font-medium text-primary-900 transition-colors focus:border-primary-300 focus:border-opacity-100;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

#location-input {
  @apply !opacity-100 focus:!outline-none focus:!ring-transparent;
}

.MuiFormControl-root input[type='text'] {
  @apply pointer-events-none !bg-transparent font-sans;
}

.MuiInputBase-input input[type='text'] {
  @apply !font-medium;
}

.MuiOutlinedInput-notchedOutline {
  @apply !border-none;
}

.MuiDialogContent-root,
.MuiPickersLayout-root {
  @apply !rounded-[20px] !bg-secondary-50;
}

.MuiPaper-rounded {
  @apply !rounded-[20px];
}

.MuiTypography-root,
.MuiButton-textPrimary {
  @apply !text-primary-900;
}

.MuiTabs-indicator,
.MuiClockPointer-root,
.MuiClock-pin,
.Mui-selected.MuiButtonBase-root:not(.MuiTab-root) {
  @apply !bg-secondary !text-primary-900;
}

.MuiClockPointer-thumb {
  @apply !border-secondary !bg-secondary-50;
}

.MuiSvgIcon-root {
  @apply !text-primary-900;
}

.Mui-selected .MuiSvgIcon-root {
  @apply !text-secondary;
}

input[type='checkbox'] {
  @apply h-5 w-5 cursor-pointer rounded-full border-primary bg-secondary-50 text-primary-300 accent-primary-900 focus:ring-0 focus:ring-transparent;
}

textarea {
  @apply rounded-[20px];
}

/* width */

#scrollable-labels::-webkit-scrollbar {
  width: 14px;
}

/* Track */

#scrollable-labels::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */

#scrollable-labels::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}

body {
  color: #2f2e41;
}

/* Handle on hover */

#scrollable-labels::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

#scrollable-labels::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

#scrollable-labels::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

.modal-wrapper {
  z-index: 9999;
}

.search-bar {
  z-index: 1;
}

.popup-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;

  /* Add vertical padding below the bar */
  margin-bottom: 1rem;
}

.custom-text-selection::selection {
  background-color: #d8f4f0;
}

.icon {
  /* stroke-width: 2.5; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.PhoneInputInput {
  @apply h-[50px] rounded-[15px] border-2 border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50 font-medium text-primary-900 transition-colors placeholder:text-opacity-50 focus:border-primary-300;
}

/* Tailwind css variables cannot be resolved  */
/*noinspection ALL */

:focus.PhoneInputInput {
  outline: none;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
}

.invalid > .PhoneInputInput {
  @apply border-danger;
}

.PhoneInput {
  width: 100%;
}

:root {
  --PhoneInput-color--focus: #01afa5;
}

/* Hide scrollbar for Chrome, Safari and Opera */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  margin-right: -50vw;
}

#root {
  white-space: pre-line;
}

[data-test-id='button-primary'] {
  color: #fceeeb !important;
  @apply !flex !h-fit !w-fit !items-center !gap-1 whitespace-nowrap !rounded-[20px] !bg-primary !px-[16px] !py-[8px] !font-[600] transition-all hover:!bg-opacity-80 focus:!ring-0 disabled:!opacity-20 disabled:hover:!bg-opacity-100;
}

[data-test-id='button-skip'] {
  color: #fceeeb !important;
  @apply !flex !h-fit !w-fit !items-center !gap-1 whitespace-nowrap !rounded-[20px] !bg-secondary-200 !px-[16px] !py-[8px] !text-base !font-[600] !text-primary-900 transition-all hover:!bg-opacity-80 disabled:!opacity-20 disabled:hover:!bg-opacity-100;
}

[data-test-id='button-primary'] span {
  @apply !text-base !text-secondary-50;
}

div:has(+ button[data-test-id='button-primary']) {
  @apply !flex-initial;
}

div:has(> button[data-test-id='button-primary']) {
  @apply !mt-0 !justify-start gap-2 px-4 pb-5;
}

.react-joyride__tooltip {
  @apply !p-0;
}

div:has(> #onboarding-conainer) {
  @apply !px-4 pt-5;
}

/* div:has(> button[title='Last']) {
  display: none !important;
} */
